import React from "react";
import Layout from "../components/layout";
import "../styles/pages/ui.scss";
import $ from "jquery/dist/jquery";

class UIPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

        let viewPort = {
            "width" : $(window).width(),
            "height" : $(window).height()
        }
        let breakpoint = $('.breakpoint');

        function updateBreaktpoint(container) {
            if ( viewPort.width <= 767 ) {
                container.html("<p>Mobile</p>");
            } else if ( viewPort.width <= 1023 ) {
                container.html("<p>Tablet</p>");
            } else {
                container.html("<p>Desktop</p>");
            }
        }

        $(window).on('resize', () => {
            viewPort.width = $(window).width();
            viewPort.height = $(window).height();
            updateBreaktpoint(breakpoint);
        });

        console.log(viewPort);
        updateBreaktpoint(breakpoint);

    }

    render() {
        return(
            <Layout>
                <div className="ui-layout">
                    <h1>UI / UX Page</h1>

                    <div className="breakpoint"></div>
                    
                    <h3>Headings</h3>

                    <p>H1:</p>
                    <h1>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h1>

                    <p>H2:</p>
                    <h2>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h2>

                    <p>H3:</p>
                    <h3>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h3>

                    <p>H4:</p>
                    <h4>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h4>

                    <p>H5:</p>
                    <h5>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h5>

                    <p>H6:</p>
                    <h6>Lorem ipsum dolor sit amet,<br/>consectetur adipiscing elit.</h6>

                    <p>p, a, li, span, strong:</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nibh augue, pretium et rhoncus a, facilisis eget est.<br/>Fusce rhoncus justo quis erat feugiat egestas. Pellentesque venenatis iaculis porttitor. <strong>Proin id mi mattis, lacinia enim vel, gravida mauris.</strong> Sed vehicula porta risus <a href="#0">sagittis venenatis</a>.</p>
                    
                    <ul>
                        <li>Suspendisse eu tristique ante.</li>
                        <li>In mattis ipsum id purus pulvinar, ac fermentum nunc efficitur.</li>
                        <li>Aenean nec risus eu nunc vehicula posuere.</li>
                        <li>Cras sagittis ipsum ut convallis convallis.</li>
                        <li>Quisque lorem lorem, suscipit at enim nec, porta auctor ex.</li>
                    </ul>

                    <p>p.slim:</p>

                    <p className="slim">Vestibulum sit amet neque in neque dignissim sollicitudin in vitae risus. Quisque ut enim semper, placerat elit eget, ultricies lectus. Donec ultricies purus id nibh varius scelerisque. Nullam ultricies volutpat nunc, ut varius eros porttitor id. Nam vel neque egestas tortor congue hendrerit vel ac nunc.</p>

                    <hr/>

                    <p>Buttons &amp; Inputs .btn, .btn-primary, .btn-secondary:</p>

                    <p>&nbsp;</p>

                    <p><input type="submit" value="Submit Input" /></p>
                    <p><a className="btn" href="#0">Click me</a>&nbsp;<a className="btn btn-primary" href="#0">Click me</a>&nbsp;<a className="btn btn-secondary" href="#0">Click me</a></p>

                    <hr/>

                    <p>Color pallette:</p>

                    <ul className="swatch">
                        <li className="bg-turquise"></li>
                        <li className="bg-green-sea"></li>
                        <li className="bg-nephritis"></li>
                        <li className="bg-emerald"></li>
                        <li className="bg-belize-hole"></li>
                        <li className="bg-peter-river"></li>
                        <li className="bg-wisteria"></li>
                        <li className="bg-amethyst"></li>
                        <li className="bg-midnight-bue"></li>
                        <li className="bg-wet-asphalt"></li>
                        <li className="bg-sunflower"></li>
                        <li className="bg-orange"></li>
                        <li className="bg-carrot"></li>
                        <li className="bg-alizarin"></li>
                        <li className="bg-pumpkin"></li>
                        <li className="bg-pomegrape"></li>
                    </ul>

                </div>
            </Layout>
        )
    }
}

export default UIPage